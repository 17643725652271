<template>
  <page-container>
    <div class="layout">
      <div style="width: 100%; display: flex; align-items: center">
        <el-form
          :inline="true"
          :model="searchModel"
          style="margin-top: 20px; padding-left: 20px"
          size="mini"
        >

          <!--   <el-form-item label="所属企业:" prop="compName">
                       <el-select
                               v-model="searchModel.compName"
                               placeholder="请选择所属企业"
                               filterable
                               clearable
                               style="width: 200px"
                       >
                           <el-option
                                   v-for="item in companyList"
                                   :key="item.compId"
                                   :label="item.compName"
                                   :value="item.compId"
                           >
                           </el-option>
                       </el-select>
                   </el-form-item>-->

          <el-form-item label="查询时间:">
            <el-date-picker
              v-model="searchModel.yearMonth"
              type="month"
              placeholder="选择年月"
              style="width: 180px"
            >
            </el-date-picker>
          </el-form-item>
          <!--   <el-form-item label="结束:">
                       <el-date-picker
                               v-model="searchModel.endDate"
                               type="month"
                               placeholder="选择年月"
                               style="width: 180px"
                       >
                       </el-date-picker>
                   </el-form-item>-->
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="submitSearch"
              :loading="loading"
            >{{ searchText }}</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-close"
              v-if="loading"
              @click="loading = false"
            >停止</el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <resize-wrap class="flex-1">
        <BaseTable
          id="mortarMonth"
          ref="xTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :align="allAlign"
          :data="tableData"
          :seq-config="{startIndex: (tablePage.currentPage - 1) * tablePage.pageSize}"
        >
          <template #buttons>
            <el-button
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="dialogEdit = true;addRow()"
            >新增</el-button>
          </template>
          <vxe-table-column type="seq" title="序号" width="60" />
          <vxe-table-column
            field="compName"
            title="企业名称"
            show-overflow="title"
            min-width="150"
            show-header-overflow="title"/>
          <vxe-table-column
            field="areaName"
            title="所属区域"
            show-overflow="title"
            min-width="150"
            show-header-overflow="title"/>
          <vxe-table-column  v-for="column of columns" :field="column.itemCode" :title="column.itemName" show-overflow="title" min-width="60" :key="column.itemCode"/>
          <vxe-table-column
            field="lecDate"
            title="报告日期"
            show-overflow="title"
            min-width="150"
            show-header-overflow="title" />
          <vxe-table-column title="操作" min-width="100">
            <template v-slot="{ row }">
              <el-button type="success" icon="el-icon-top" circle size="mini" @click="uploadRow(row)" v-if="row.isUpload == 0" title="上报"/>
              <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editRow(row)" title="修改"/>
              <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="deletetRow(row)" title="删除"/>
            </template>
          </vxe-table-column>
        </BaseTable>
      </resize-wrap>
      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="['Sizes','PrevPage','JumpNumber','NextPage','FullJump','Total']"
        @page-change="handlePageChange"
      />
      <el-dialog :title="dialogEditTitle"
                 :visible.sync="dialogEdit"
                 :v-if="dialogEdit"
                 :append-to-body="true"
                 width="800px"
                 v-dialogDrag
                 :close-on-click-modal="false"
                 :close-on-press-escape="false">

        <el-form :inline="true" ref="addForm" size="mini" label-position="right" label-width="170px" style="margin-top: 20px">
          <template v-for="(item, index) of uploadColumns">
            <el-form-item :label="item.itemName" :prop="item.itemCode" :key="index">
              <el-select v-model="editModel[item.itemCode]" v-if="item.sonItemList&&item.sonItemList.length>0">
                <el-option
                  v-for="son in item.sonItemList"
                  :key="son.itemCode"
                  :label="son.itemName"
                  :value="son.itemValue">
                </el-option>
              </el-select>
              <el-input  v-model="editModel[item.itemCode]" clearable  v-else>
              </el-input>
            </el-form-item>
          </template>
          <el-form-item label="报告日期:">
            <el-date-picker
              v-model="editModel.specifyDate"
              type="month"
              placeholder="选择年月"
              style="width: 180px"
              value-format="yyyy-MM"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogEdit = false" size="mini">取消</el-button>
          <el-button type="primary" @click="onSubmitAdd()" size="mini">保存</el-button>
        </div>
      </el-dialog>

    </div>
  </page-container>
</template>

<script>
import { getMonthUpleReportList, deleteUpleData, getDataColumn, postUpleData, uploadUpleData } from '@/apis/dataReport'
import moment from 'moment'
import XEUtils from 'xe-utils'

export default {
  name: 'mortar-month',
  data () {
    return {
      loading: false,
      dialogEdit: false,
      tableData: [],
      searchModel: {
        compName: '',
        yearMonth: moment().format('YYYY-MM')
      },
      companyList: [],
      searchText: '查询',
      allAlign: 'center',
      columns: [],
      checkedRow: false,
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: ['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total'],
        perfect: true
      },
      uploadColumns: [],
      editModel: {
        specifyDate: ''
      },
      dialogEditTitle: ''
    }
  },
  methods: {
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    refreshData () { // todaymissing,alert单独处理
      this.loading = true
      getMonthUpleReportList({
        ...this.searchModel,
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
        type: 4 // 砂浆月报表
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          const data = res.data
          // 清空列表数据 、列名数组
          const rows = []
          let columns = []
          data.rows.forEach((listEve, index) => {
            let rowData = {} // 行数据
            rowData = Object.assign({}, listEve)
            listEve.itemDtoList.forEach(dtoEve => {
              // code->value
              rowData[dtoEve.itemCode] = dtoEve.itemValue
              rowData.id = dtoEve.itemId
              if (index < 1) {
                columns.push(dtoEve)
              }
            })
            rows.push(rowData)
          })
          columns = XEUtils.orderBy(columns, [['itemOrder', 'asc']])
          this.columns = columns
          this.tableData = rows
          this.tablePage.total = data.totalSize
        } else {
          this.tableData = []
          this.tablePage.total = 0
        }
      }).catch(error => {
        console.log(error)
        //  this.logining = false;
      })
    },
    submitSearch () {
      const params = {
        startDate: this.searchModel.startDate,
        endDate: this.searchModel.endDate
      }
      const startDate = moment(params.startDate)
      const endDate = moment(params.endDate)
      const diffSecond = endDate.diff(startDate, 'seconds')
      if (diffSecond < 0) {
        this.$message({
          message: '开始时间不能晚于结束时间',
          type: 'warning'
        })
        return
      }
      // if (diffSecond > 86400 * 31) {
      //     this.$message({
      //         message: '查询时间段不能超过31天',
      //         type: 'warning'
      //     });
      //     return;
      // }
      this.loading = true
      this.tableData = []
      this.tablePage.currentPage = 1
      // this.currentBusIndex = 0;
      this.refreshData()
    },
    editRow (row) {
      this.dialogEditTitle = '修改砂浆月报记录'
      const rowData = {}
      // 对象迭代器，只能用于遍历对象，性能高于 each
      XEUtils.objectEach(this.editModel, (item, key) => {
        if (key == 'specifyDate') { rowData.specifyDate = row.lecDate } else { rowData[key] = row[key] ? row[key] : '' }
      })

      this.editModel = rowData
      this.dialogEdit = true
    },
    deletetRow (row) {
      deleteUpleData({
        id: row.upleCompId
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功！')
          this.refreshData()
        } else { this.$message.error('删除失败！') }
      })
    },
    stopSearch () {
      this.loading = false
      this.searchText = '查询'
    },
    addRow () {
      this.dialogEditTitle = '新增砂浆月报记录'
      XEUtils.objectEach(this.editModel, (key, item) => {
        this.editModel[key] = ''
        // 对象迭代器，只能用于遍历对象，性能高于 each
      })
    },
    uploadRow (row) {
      uploadUpleData({
        upleCompId: row.upleCompId
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('上报成功！')
          this.refreshData()
        } else { this.$message.error('上报失败！') }
      })
    },
    onSubmitAdd () {
      const submitColumns = []
      this.uploadColumns.forEach(item => {
        let colParams = Object.assign({}, item)
        colParams.itemValue = this.editModel[item.itemCode]
        // 排除子列表
        colParams = XEUtils.omit(colParams, 'sonItemList')
        submitColumns.push(colParams)
      })

      postUpleData({
        columnValueList: submitColumns,
        specifyDate: this.editModel.specifyDate,
        type: 4 // 砂浆月报表上报
      }).then(res => {
        if (res.code === 200) {
          this.dialogEdit = false
          this.$message.success('保存成功！')
          this.refreshData()
        } else { this.$message.error('保存失败！') }
      })
    }
  },
  mounted () {
    // 获取数据字段
    getDataColumn({
      type: 60 // 砂浆月报表上报
    }).then(res => {
      if (res.code == 200) {
        const editModel = {
          specifyDate: ''
        }
        res.data.forEach(item => {
          editModel[item.itemCode] = ''
          item.sonItemList = XEUtils.orderBy(item.sonItemList, [['itemOrder', 'asc']])
        })
        this.uploadColumns = XEUtils.orderBy(res.data, [['itemOrder', 'asc']])
        this.editModel = editModel
      }
    })
    /*     gianComplist({
                     pageNum:1,
                     pageSize:999
                 }).then(res => {
                     if (res.code == 200) {
                         this.companyList = res.data.rows
                         console.log('companyList',this.companyList)
                     }
                 }).catch(res => {
                     console.log(res)
                 })
     */
    this.refreshData()
  }
}
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background-color: white;
}
</style>
